$app-bg-color:#282c34;

body {
  background-color: #282c34;
  min-height: 100vh;
  margin: 0;
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input {
  font-size: 2em;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.text-white {
  color: white !important;
}

.text-gray {
  color: #6a708e !important;
}

.text-bgcolor {
  color: $app-bg-color;
}

.green {
  background-color: #6faf62 !important;
}

.yellow {
  background-color: #ddaa28 !important;
}

.white {
  background-color: #6a708e !important;
}

.clickable {
  cursor: pointer;
}

.material-icons {
  font-size: 20px;
}

p {
  margin-bottom: .8rem;
}