// .main {
//   min-height: 90vh;
// }

.App {
  text-align: center;
  color: white;
}

input {
  color: white;
  font-size: 1.2em;
}

ul {
  list-style-type: none;
}

.guess {
  font-size: 1.8em;
  margin-top: 8px;
}

.delete {
  margin-left: 36px;
  cursor: pointer;
}

.box {
  display: inline-block;
  margin-left: 8px;
  padding: 4px 14px !important;
  width: auto !important;

  &.missed-guess {
    background-color: #4b4f64 !important;
  }
}

.error {
  font-weight: 700;
  color: red;
  margin-bottom: 1rem;
}

.active-box {
  display: inline;
  height: 24px;
  width: 16px;
}

.guess {
  display: flex;
  margin-bottom: 0.2rem;
}

h1 {
  margin-bottom: 1rem;
}

$dark-gray: #282c34;

.entry-form {
  input[type='text'] {
    background-color: $dark-gray;
    font-size: 2em;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}
