.keyboard {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .keyboard-row {
    margin: 18px 3% 20px 3%;
  }
  
  .keyboard-key {
      border-radius: 2px;
    display: inline;
    padding: 8px;
    color: black;
    background-color: white;
  }
  